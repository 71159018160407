import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import CompsCardBlitzAnalyzed from "@/game-tft/CompsCardBlitzAnalyzed.jsx";
import { isFutureSet, isRevivalSet } from "@/game-tft/constants.mjs";
import parseCompName from "@/game-tft/get-comp-name.mjs";
import getStatFilters from "@/game-tft/get-stats-filters.mjs";
import { useLocalizedChampions } from "@/game-tft/use-champions.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { useSnapshot } from "@/util/proxy.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

const CONFIG = {
  title: ["common:navigation.comps", "Comps"],
  description: [
    "home:guides.tft.comps.description",
    "Master the Teamfight Tactics meta with the best TFT comps and guides. Blitz.gg provides the most up-to-date strategies and tier lists.",
  ],
  buttonText: ["common:navigation.viewComps", "View Comps"],
  buttonLink: "/tft/comps",
};

export default function HomeCompsTile() {
  const { searchParams } = useRoute();
  const {
    i18n: { language },
  } = useTranslation();

  const state = useSnapshot(readState);

  const set = useSetByParam();
  const {
    tft: {
      championPositioning: championPositioningData,
      pbePatch,
      latestPatch: latestPatchData,
    },
  } = state;
  const {
    champions = {},
    items: itemsStaticData = {},
    traits = {},
    augments = {},
  } = state.tft?.[set] ?? {};
  const isPBE = isFutureSet(set);

  const latestPatch = isPBE ? pbePatch : latestPatchData;
  const setTraits = traits;

  const championsStaticData = champions;
  const championPositioning = championPositioningData?.[latestPatch?.patch];
  const itemsLocalized = state.tft[set]?.itemsLocalized?.[language];
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const defaultFilters = state.tft.defaultFilters;
  const defaultMode = defaultFilters?.default_mode.mode;
  const defaultRank = defaultFilters?.modes_default_rank?.[defaultMode]?.rank;
  const compsFilters = getStatFilters(
    searchParams,
    "comps",
    isPBE,
    isRevivalSet(set),
    defaultMode,
    defaultRank,
  );
  const comps =
    state.tft.stats.comps[compsFilters]?.[set] &&
    state.tft.stats.comps[compsFilters]?.[set]
      ?.sort(
        (a, b) =>
          a.stats.tier - b.stats.tier ||
          a.stats.avg_placement - b.stats.avg_placement,
      )
      ?.slice(0, 3);

  const isLoading = !comps;

  if (isLoading) {
    return (
      <GameGuideTileLg
        title={CONFIG.title}
        description={CONFIG.description}
        buttonText={CONFIG.buttonText}
        href={CONFIG.buttonLink}
        empty={!comps?.length}
      >
        <LoadingComp className="loading-row" style={{ "--delay": "0.00s" }} />
        <LoadingComp className="loading-row" style={{ "--delay": "0.05s" }} />
        <LoadingComp className="loading-row" style={{ "--delay": "0.10s" }} />
      </GameGuideTileLg>
    );
  }

  return (
    <GameGuideTileLg
      title={CONFIG.title}
      description={CONFIG.description}
      buttonText={CONFIG.buttonText}
      href={CONFIG.buttonLink}
      empty={!comps?.length}
    >
      <CompCardContainer>
        {comps?.map((comp, i) => (
          <li key={comp.name + i}>
            <CompCardItemLink
              set={set}
              championsStatic={championsStaticData}
              setTraits={setTraits}
              comp={comp}
            />

            <CompsCardBlitzAnalyzed
              set={set}
              augments={augments}
              itemStatic={itemsStaticData}
              itemsLocalized={itemsLocalized}
              championsStatic={championsStaticData}
              championPositioning={championPositioning}
              skinSetting={isSkinsEnabled}
              latestPatch={latestPatch}
              compFilters={compsFilters}
              setTraits={setTraits}
              comp={comp}
              filters={[]}
              style={{ "--border-radius": 0 }}
              disableExpand={true}
              // scrollToCompId={scrollToCompId}
            />
          </li>
        ))}
      </CompCardContainer>
    </GameGuideTileLg>
  );
}

function CompCardItemLink({ set, championsStatic, setTraits, comp }) {
  const localizedChampions = useLocalizedChampions(set);

  const { t, i18n } = useTranslation();

  return (
    <a
      href={`/tft/comps?clusterId=${comp.cluster_id}`}
      aria-label={parseCompName({
        t,
        i18n,
        name: comp.name,
        api_name: comp.api_name,
        set,
        localizedChampions,
        setTraits,
        championsStatic,
      })}
    />
  );
}

const LoadingComp = styled("div")`
  position: relative;
  height: 7.5rem;
  box-shadow: inset 0 -1px var(--card-inset-border);
`;

const CompCardContainer = styled("ul")`
  position: relative;

  container-type: inline-size;
  container-name: comps-preview;

  & > li {
    & > a {
      position: absolute;
      inset: 0;
    }

    & > div {
      position: relative;

      pointer-events: none;
      z-index: 1;
    }
  }
`;
